import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

function Navbar({ toggleSidebar }) {
  const [adminName, setAdminName] = useState('');
  const navigate = useNavigate();
  const collectorId = localStorage.getItem('shristi_collector_id');

  useEffect(() => {
    const fetchAdminDetails = async () => {
      if (collectorId) {
        try {
          const response = await fetch(`https://srishticooperativesociety.com/api/admins/${collectorId}`);
          if (response.ok) {
            const data = await response.json();
            setAdminName(data.name);
            console.log(adminName)
          } else {
            console.error('Failed to fetch admin details');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchAdminDetails();
  }, [collectorId]);

  const handleLogout = () => {
    localStorage.removeItem('shristi_collector_id');
    navigate('/authpage');
    window.location.reload();
  };

  return (
    <div className="navbar">
      <FaBars className="menu-icon" onClick={toggleSidebar} />
      <h1>FieldOfficer ({adminName})</h1>
      <div className="user-info">
    
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>
    </div>
  );
}

export default Navbar;
