import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthPage.css';

const AuthPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [branchId, setBranchId] = useState(''); // Selected branch_id
    const [branches, setBranches] = useState([]); // Store branch data
    const [designation] = useState('collector'); // Default to 'Collector'
    const [address, setAddress] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [tab, setTab] = useState('login'); // Toggle between 'login' and 'signup'
    const [error, setError] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
    const navigate = useNavigate();

    useEffect(() => {
        const collectorId = localStorage.getItem('shristi_collector_id');
        if (collectorId) {
            navigate('/dashboard');
        }
    }, [navigate]);

    // Fetch branches when component mounts
    useEffect(() => {
        const fetchBranches = async () => {
            try {
                const response = await fetch('https://srishticooperativesociety.com/api/branches');
                const data = await response.json();
                setBranches(data);
            } catch (error) {
                console.error('Error fetching branches:', error);
                setError('Failed to load branch data.');
            }
        };

        fetchBranches();
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://srishticooperativesociety.com/api/admin/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const text = await response.text();
            let data;

            try {
                data = JSON.parse(text);
            } catch (e) {
                console.error('Login response is not JSON:', text);
                setError('Server returned an unexpected response format.');
                return;
            }

            if (response.ok) {
                if (data.designation === 'collector') {
                    localStorage.setItem('shristi_collector_id', data.id);
                    localStorage.setItem('srishti_branch_id', data.branch_id);
                    window.location.reload();
                } else {
                    setError('You do not have collector privileges.');
                }
            } else {
                setError(data.message || 'Invalid credentials. Please try again.');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('An error occurred. Please try again later.');
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous error messages
        console.log('Attempting to signup with:', { 
            email, 
            password, 
            designation, 
            branch_id: parseInt(branchId, 10), // Ensure branch_id is an integer
            address, 
            phone_no: parseInt(phoneNo, 10), // Ensure phone number is an integer
            name 
        });

        try {
            const response = await fetch('https://srishticooperativesociety.com/api/admin/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    email, 
                    password, 
                    designation, 
                    branch_id: parseInt(branchId, 10), // Ensure branch_id is an integer
                    address, 
                    phone_no: parseInt(phoneNo, 10), // Ensure phone number is an integer
                    name 
                }),
            });

            const text = await response.text();
            let data;

            try {
                data = JSON.parse(text);
            } catch (e) {
                console.error('Signup response is not JSON:', text);
                setError('Server returned an unexpected response format.');
                return;
            }

            console.log('Signup response:', data);

            if (response.ok) {
                alert('Signup successful!');
                setTab('login');
            } else {
                setError(data.message || 'Failed to signup. Please try again.');
            }
        } catch (error) {
            console.error('Signup error:', error);
            setError('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-form-container">
                <div className="tabs">
                    <button onClick={() => setTab('login')} className={tab === 'login' ? 'active' : ''}>Login</button>
                    <button onClick={() => setTab('signup')} className={tab === 'signup' ? 'active' : ''}>Signup</button>
                </div>
                {tab === 'login' ? (
                    <form onSubmit={handleLogin} className="auth-form">
                        <h2>Login</h2>
                        {error && <p className="error">{error}</p>}
                        <div className="input-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="password">Password:</label>
                            <input
                                type={passwordVisible ? 'text' : 'password'} // Toggle password visibility
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <div className="password-visibility">
                                <input
                                    type="checkbox"
                                    id="togglePassword"
                                    checked={passwordVisible}
                                    onChange={() => setPasswordVisible(!passwordVisible)}
                                />
                                <label htmlFor="togglePassword">Show Password</label>
                            </div>
                        </div>
                        <button type="submit">Login</button>
                    </form>
                ) : (
                    <form onSubmit={handleSignup} className="auth-form">
                        <h2>Signup</h2>
                        {error && <p className="error">{error}</p>}
                        <div className="input-group">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="password">Password:</label>
                            <input
                                type={passwordVisible ? 'text' : 'password'} // Toggle password visibility
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <div className="password-visibility">
                                <input
                                    type="checkbox"
                                    id="togglePassword"
                                    checked={passwordVisible}
                                    onChange={() => setPasswordVisible(!passwordVisible)}
                                />
                                <label htmlFor="togglePassword">Show Password</label>
                            </div>
                        </div>
                        <input
                            type="hidden"
                            id="designation"
                            value={designation}
                            readOnly
                        />
                        <div className="input-group">
                            <label htmlFor="branchId">Branch:</label>
                            <select
                                id="branchId"
                                value={branchId}
                                onChange={(e) => setBranchId(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select Branch</option>
                                {branches.map(branch => (
                                    <option key={branch.id} value={branch.id}>
                                        {branch.branchname}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group">
                            <label htmlFor="address">Address:</label>
                            <input
                                type="text"
                                id="address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="phoneNo">Phone No:</label>
                            <input
                                type="text"
                                id="phoneNo"
                                value={phoneNo}
                                onChange={(e) => setPhoneNo(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit">Signup</button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default AuthPage;
