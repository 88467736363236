import React, { useState, useEffect } from 'react';
import './Commisions.css';

const TotalCommisions = () => {
  // Dummy data for collections
  const [collections, setCollections] = useState([
    { id: 1, collector_id: 1, collection_amount: 10000, collection_date: '2024-07-01' },
    { id: 2, collector_id: 2, collection_amount: 15000, collection_date: '2024-07-03' },
    { id: 3, collector_id: 1, collection_amount: 12000, collection_date: '2024-07-05' },
    { id: 4, collector_id: 3, collection_amount: 8000, collection_date: '2024-07-07' },
    { id: 5, collector_id: 2, collection_amount: 20000, collection_date: '2024-07-10' },
  ]);

  // State for total commission
  const [totalCommission, setTotalCommission] = useState(0);

  useEffect(() => {
    calculateTotalCommission();
  }, []);

  // Calculate total commission
  const calculateTotalCommission = () => {
    let total = 0;
    collections.forEach(collection => {
      total += collection.collection_amount * 0.03; // Commission rate of 3%
    });
    setTotalCommission(total);
  };

  return (
    <div className="total-commissions-container">
      <h2>Total Commissions</h2>
      <table className="commissions-table">
        <thead>
          <tr>
            <th>Collector ID</th>
            <th>Collection Amount</th>
            <th>Commission Earned</th>
            <th>Collection Date</th>
          </tr>
        </thead>
        <tbody>
          {collections.map(collection => (
            <tr key={collection.id}>
              <td>{collection.collector_id}</td>
              <td>₹{collection.collection_amount}</td>
              <td>₹{parseFloat(collection.collection_amount * 0.03).toFixed(2)}</td>
              <td>{collection.collection_date}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="total-commission">
        <strong>Total Commission Earned:</strong> ₹{totalCommission.toFixed(2)}
      </div>
    </div>
  );
};

export default TotalCommisions;
