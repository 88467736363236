import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CollectionDates.css';

const CollectionDates = () => {
  const [loanRequests, setLoanRequests] = useState([]);
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [searchFirstName, setSearchFirstName] = useState('');
  const [searchLastName, setSearchLastName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLoanRequests = async () => {
      try {
        const collectorId = localStorage.getItem('shristi_collector_id');
        if (!collectorId) {
          console.error('Collector ID not found in local storage.');
          return;
        }

        const response = await fetch(`https://srishticooperativesociety.com/api/loan-requests/collector/${collectorId}`);
        const data = await response.json();
        setLoanRequests(data);
        setFilteredLoans(data); // Initialize with full data
      } catch (error) {
        console.error('Error fetching loan requests:', error);
      }
    };

    fetchLoanRequests();
  }, []);

  useEffect(() => {
    // Filter the loan requests based on the search inputs
    const filtered = loanRequests.filter((loan) => {
      return (
        loan.firstname.toLowerCase().includes(searchFirstName.toLowerCase()) &&
        loan.lastname.toLowerCase().includes(searchLastName.toLowerCase())
      );
    });
    setFilteredLoans(filtered);
  }, [searchFirstName, searchLastName, loanRequests]);

  const handleDoCollection = (loanId) => {
    navigate(`/collection-process/${loanId}`);
  };

  const handleDownloadForm = (loanId) => {
    if (loanId) {
      navigate(`/download-form/${loanId}`);
    }
  };

  return (
    <div className="collection-dates-container">
      <h1>Loans Assigned to You</h1>

      <div className="filter-container">
        <input
          type="text"
          placeholder="Search by First Name"
          value={searchFirstName}
          onChange={(e) => setSearchFirstName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by Last Name"
          value={searchLastName}
          onChange={(e) => setSearchLastName(e.target.value)}
        />
      </div>

      <table className="loan-requests-table">
        <thead>
          <tr>
            <th>Loan ID</th>
            <th>FirstName</th>
            <th>LastName</th>
            <th>Loan Type</th>
            <th>Amount</th>
            <th>Interest Rate</th>
            <th>Duration</th>
            <th>Installment Type</th>
            <th>Approval Status of Loan</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredLoans.map((loan) => (
            <tr key={loan.id}>
              <td>{loan.id}</td>
              <td>{loan.firstname}</td>
              <td>{loan.lastname}</td>
              <td>{loan.loan_type}</td>
              <td>₹{loan.amount}</td>
              <td>{loan.interest_rate}%</td>
              <td>{loan.duration} Months</td>
              <td>{loan.installment_type}</td>
              <td>{loan.approval_status}</td>
              <td>
                <button onClick={() => handleDoCollection(loan.id)}>Do a Collection</button>
                <button onClick={() => handleDownloadForm(loan.id)}>Download Form</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <h2>Installment Dates for Loan ID: {selectedLoan.id}</h2>
            <table className="installments-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {installments.map((installment, index) => (
                  <tr key={index}>
                    <td>{installment.date}</td>
                    <td>{installment.amount}</td>
                    <td>{installment.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollectionDates;
