import React, { useState, useEffect } from 'react';
import './Collections.css';

const Collection = () => {
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [collectionDate, setCollectionDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [collectedFrom, setCollectedFrom] = useState(''); // New filter field
  const [totalCollections, setTotalCollections] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [commissionRate, setCommissionRate] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectorId = localStorage.getItem('shristi_collector_id');
        if (!collectorId) {
          console.error('Collector ID not found in local storage.');
          return;
        }

        // Fetch collections data
        const collectionsResponse = await fetch(`https://srishticooperativesociety.com/api/collections/collector/${collectorId}`);
        const collectionsData = await collectionsResponse.json();

        // Fetch commission rate
        const commissionRateResponse = await fetch('https://srishticooperativesociety.com/api/variables/2');
        const commissionRateData = await commissionRateResponse.json();
        const rate = parseFloat(commissionRateData.value);
        console.log('Fetched commission rate:', rate); // Log the commission rate

        setCommissionRate(rate);

        // Set all collections for display
        setCollections(collectionsData);
        setFilteredCollections(collectionsData);

        // Calculate totals from accepted collections
        const acceptedCollections = collectionsData.filter(item => item.status === 'accepted');
        calculateTotals(acceptedCollections, rate);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFilter = () => {
    let filtered = collections;

    if (collectionDate) {
      filtered = filtered.filter(item => item.collection_date.startsWith(collectionDate));
    }
    
    if (startDate && endDate) {
      filtered = filtered.filter(item => {
        const collectionDate = new Date(item.collection_date);
        return collectionDate >= new Date(startDate) && collectionDate <= new Date(endDate);
      });
    }

    if (collectedFrom) {
      filtered = filtered.filter(item => item.collected_from.toLowerCase().includes(collectedFrom.toLowerCase()));
    }

    setFilteredCollections(filtered);

    // Recalculate totals based on filtered collections with accepted status
    const acceptedCollections = filtered.filter(item => item.status === 'accepted');
    calculateTotals(acceptedCollections, commissionRate);
  };

  const calculateTotals = (data, rate) => {
    const totalAmount = data.reduce((acc, item) => acc + parseFloat(item.collection_amount), 0);
    const commission = totalAmount * (rate / 100); // Correctly calculate commission rate as a percentage

    setTotalCollections(totalAmount.toFixed(2));
    setTotalCommission(commission.toFixed(2));
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  return (
    <div className="collection-container">
      <h1>Collections</h1>
      
      <div className="filters">
       

      <div className='combined-filter'>
      <div className="filter-group">
          <label htmlFor="startDate">Start Date:</label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>

        <div className="filter-group">
          <label htmlFor="endDate">End Date:</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>

        <div className="filter-group">
          <label htmlFor="collectedFrom">Collected From:</label>
          <input
            type="text"
            id="collectedFrom"
            value={collectedFrom}
            onChange={(e) => setCollectedFrom(e.target.value)}
          />
        </div>

        <button type="button" onClick={handleFilter}>Apply Filters</button>
      </div>

      <div className="summary">
        <div className="summary-card">
          <h3>Total Collections (Accepted by Branch)</h3>
          <p>₹{totalCollections}</p>
        </div>
        <div className="summary-card">
          <h3>Total Commission</h3>
          <p>₹{totalCommission}</p>
        </div>
      </div>

      <table className="collections-table">
        <thead>
          <tr>
            <th>Loan ID</th>
            <th>Collected From</th>
            <th>Collection Amount</th>
            <th>Collection Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredCollections.map(collection => (
            <tr key={collection.id}>
              <td>{collection.loan_id}</td>
              <td>{collection.collected_from}</td>
              <td>₹{collection.collection_amount}</td>
              <td>{formatDate(collection.collection_date)}</td>
              <td>
                {collection.status === 'accepted' ? 'Approved By Branch Manager' : 'Pending'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Collection;
