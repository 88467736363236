import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import Navbar from './components/navbar/Navbar';
import Dashboard from './pages/dashboard/Dashboard';
import './App.css';
import Collections from './pages/collections/Collections';
import CollectionDates from './pages/collectiondates/CollectionDates';
import TotalCommisions from './pages/commisions/Commisions';
import CollectionProcess from './pages/collection-process/CollectionProcess';
import AuthPage from './pages/authpage/AuthPage';
import ApplyForLoan from './pages/applyloan/ApplyForLoan';
import DownloadForm from './pages/applyloan/DownloadForm';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const collectorId = localStorage.getItem('shristi_collector_id');
    if (collectorId) {
      setIsLoggedIn(true);
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('shristi_collector_id');
    setIsLoggedIn(false);
    window.location.reload(); 
  };

  return (
    <Router>
      <div className="App">
        {isLoggedIn && <Navbar toggleSidebar={toggleSidebar} handleLogout={handleLogout} />}
        <div className={`main-container ${isSidebarOpen && isLoggedIn ? 'sidebar-open' : 'sidebar-closed'}`}>
          {isLoggedIn && <Sidebar isSidebarOpen={isSidebarOpen} />}
          <div className="content">
            <Routes>
              <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/authpage" />} />
              <Route path="/authpage" element={<AuthPage />} />
              <Route path="/applyforloan" element={<ApplyForLoan />} />
              <Route path="/collections" element={isLoggedIn ? <Collections /> : <Navigate to="/authpage" />} />
              <Route path="/collection-dates" element={isLoggedIn ? <CollectionDates /> : <Navigate to="/authpage" />} />
              <Route path="/commisions" element={isLoggedIn ? <TotalCommisions /> : <Navigate to="/authpage" />} />
              <Route path="/collection-process" element={isLoggedIn ? <CollectionProcess /> : <Navigate to="/authpage" />} />
              <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/authpage" />} />

              <Route path="/collection-process/:loanId" element={<CollectionProcess />} />


              <Route path="/download-form/:id" element={<DownloadForm />} /> {/* New Route */}
      
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
