import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaRegChartBar, FaDollarSign, FaMoneyCheckAlt, FaHandHoldingUsd } from 'react-icons/fa';
import './Sidebar.css';

function Sidebar({ isSidebarOpen }) {
  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <ul>
        <li>
          <Link to="/dashboard">
            <FaHome /><span>Dashboard</span>
          </Link>
        </li>
        
        <li>
          <Link to="/collections">
            <FaRegChartBar /><span>Collections</span>
          </Link>
        </li>


        <li>
          <Link to="/collection-dates">
            <FaRegChartBar /><span>Loans Assigned</span>
          </Link>
        </li>

        <li>
          <Link to="/collection-process">
            <FaDollarSign /><span>Do a Collection</span>
          </Link>
        </li>

        <li>
          <Link to="/applyforloan">
            <FaHandHoldingUsd /><span>Apply a Loan</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
