import React, { useState, useEffect } from 'react';
import './CollectionProcess.css';
import { useParams } from 'react-router-dom';

const CollectionProcess = () => {
  const { loanId: initialLoanId } = useParams();
  const [loanId, setLoanId] = useState(initialLoanId || '');
  const [loanDetails, setLoanDetails] = useState(null);
  const [totalCollections, setTotalCollections] = useState([]);
  const [error, setError] = useState('');
  const [collectorId, setCollectorId] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [collectionAmount, setCollectionAmount] = useState('');

  useEffect(() => {
    const storedCollectorId = localStorage.getItem('shristi_collector_id');
    if (storedCollectorId) {
      setCollectorId(storedCollectorId);
    }
  }, []);

  const handleLoanSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${loanId}`);
      if (!response.ok) {
        throw new Error('Loan request not found');
      }
      const data = await response.json();
      setLoanDetails(data);
      setError('');

      // Fetch total collections for the loan ID
      const collectionsResponse = await fetch(`https://srishticooperativesociety.com/api/collections/loan/${loanId}`);
      if (collectionsResponse.ok) {
        const collectionsData = await collectionsResponse.json();
        setTotalCollections(collectionsData);
      } else {
        setTotalCollections([]);
        throw new Error('Failed to fetch collections');
      }
    } catch (error) {
      console.error('Error fetching loan details:', error);
      setLoanDetails(null);
      setError('Loan request not found');
    }
  };

  const handleCollection = () => {
    if (!loanDetails || !collectorId) return;

    setShowConfirmation(true);
  };

  const confirmCollection = async () => {
    setShowConfirmation(false);

    const collectionData = {
      loan_id: loanDetails.id,
      collector_id: parseInt(collectorId),
      collection_amount: parseFloat(collectionAmount),
      collection_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
      user_id: loanDetails.user_id,
      branch_id: 1,
      status: 'pending',
      collected_from: `${loanDetails.firstname} ${loanDetails.lastname}` // Full name added here
    };

    try {
      const response = await fetch('https://srishticooperativesociety.com/api/collections', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(collectionData)
      });

      if (response.ok) {
        console.log('Collection record saved successfully');
        setShowSuccessAlert(true);
        setLoanId('');
        setLoanDetails(null);
        setCollectionAmount('');
        setTotalCollections([]);
      } else {
        throw new Error('Failed to save collection record');
      }
    } catch (error) {
      console.error('Error saving collection record:', error);
      alert('Failed to save collection record');
    }
  };

  useEffect(() => {
    let timeout;
    if (showSuccessAlert) {
      timeout = setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [showSuccessAlert]);

  // Calculate Total Amount to be Collected and Total Amount Collected Till Now
  const totalAmountToBeCollected = loanDetails
    ? (loanDetails.amount * (1 + (loanDetails.interest_rate / 100) * loanDetails.duration)).toFixed(2)
    : '0.00';

  const totalAmountCollectedTillNow = totalCollections.reduce(
    (total, collection) => total + parseFloat(collection.collection_amount),
    0
  ).toFixed(2);

  const totalAcceptedAmount = totalCollections
    .filter(collection => collection.status === 'accepted')
    .reduce((total, collection) => total + parseFloat(collection.collection_amount), 0)
    .toFixed(2);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  return (
    <div className="collection-process-container">
      <h2>Collection Process</h2>

      <form className="loan-form" onSubmit={handleLoanSubmit}>
        <div className="form-group">
          <label htmlFor="loanId">Enter Loan ID of Customer:</label>
          <input
            type="number"
            id="loanId"
            value={loanId}
            onChange={(e) => setLoanId(e.target.value)}
            required
          />
        </div>
        <button type="submit">Do a Collection</button>
      </form>

      {loanDetails && (
        <div className="loan-details">
          <h3>Loan Details</h3>
          <p><strong>Loan Type:</strong> {loanDetails.loan_type}</p>
          <p><strong>Interest Rate:</strong> {loanDetails.interest_rate}%</p>
          <p><strong>Duration:</strong> {loanDetails.duration} Months</p>
          <p><strong>Full Name:</strong> {loanDetails.firstname} {loanDetails.lastname}</p>
          <p><strong>Total Amount:</strong> ₹{loanDetails.amount}</p>
          <p><strong>Approval Status of Loan:</strong> {loanDetails.approval_status}</p>

          <h3>Summary</h3>
          <p><strong>Total Amount to be Collected:</strong> ₹{totalAmountToBeCollected}</p>
          <p><strong>Total Amount Collected Till Now:</strong> ₹{totalAmountCollectedTillNow}</p>
          <p><strong>Amount Pending to Collect: </strong>₹{(totalAmountToBeCollected - totalAmountCollectedTillNow).toFixed(2)}</p>
          <p><strong>Total Accepted Collection Amount:</strong> ₹{totalAcceptedAmount}</p>

          <div className="form-group">
            <label htmlFor="collectionAmount">Enter Collection Amount:</label>
            <input
              type="number"
              id="collectionAmount"
              value={collectionAmount}
              onChange={(e) => setCollectionAmount(e.target.value)}
              required
            />
          </div>

          <button className="collection-button" onClick={handleCollection}>
            Collect ₹{collectionAmount}
          </button>

          <h3>Total Collections</h3>
          {totalCollections.length > 0 ? (
            <table className='table'>
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {totalCollections.map((collection) => (
                  <tr key={collection.id}>
                    <td>₹{collection.collection_amount}</td>
                    <td>{formatDate(collection.collection_date).toLocaleString()}</td>
                    <td>{collection.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No collections found for this loan.</p>
          )}
        </div>
      )}

      {error && <p className="error-message">{error}</p>}

      {showSuccessAlert && (
        <div className="success-alert">
          Your Collection Record is saved successfully.
        </div>
      )}

      {showConfirmation && (
        <div className="confirmation-popup">
          <p>Are you sure you want to process the collection?</p>
          <button onClick={confirmCollection}>Yes</button>
          <button onClick={() => setShowConfirmation(false)}>No</button>
        </div>
      )}
    </div>
  );
};

export default CollectionProcess;
