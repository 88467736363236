import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import CollectionDates from '../collectiondates/CollectionDates';

const Dashboard = () => {
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectorId = localStorage.getItem('shristi_collector_id');
        if (!collectorId) {
          console.error('Collector ID not found in local storage.');
          return;
        }

        const response = await fetch(`https://srishticooperativesociety.com/api/collections/collector/${collectorId}`);
        const data = await response.json();
        setCollections(data);
        setFilteredCollections(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  return (
    <div className="dashboard-container">
      <CollectionDates />
      <h1>Collections Dashboard</h1>
      <table className="collections-table">
        <thead>
          <tr>
            <th>Loan ID</th>
            <th>Collected From</th>
            <th>Collection Amount</th>
            <th>Collection Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredCollections.map(collection => (
            <tr key={collection.id}>
              <td>{collection.loan_id}</td>
              <td>{collection.collected_from}</td>
              <td>₹{collection.collection_amount}</td>
              <td>{formatDate(collection.collection_date)}</td>
              <td>
                {collection.status === 'accepted' ? 'Approved By Bank Manager' : 'Pending'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Dashboard;
